.common_info_app {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.common_info_app__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 17px 0;
    width: 100%;
}

.common_info_app__row > span {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #273270;
}

.common_info_app .common_info_app__row__span_left {
    color: #8387A0;
    width: 299px;
}

.border_bottom {
    border-bottom: 1px solid #E0E2E7;
}

.hidden_info {
    display: none;
}

@media screen and (max-device-width: 926px) and (max-device-height: 428px), screen and (max-device-width: 428px) {

    .common_info_app .common_info_app__row__span_left {
        margin-bottom: 10px;
    }

}

@media screen and (max-device-width: 670px) {

    .common_info_app__row {
        flex-direction: column;
        align-items: flex-start;
        padding-right: 16px;
    }

}