.custom_button__plus {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #fff;
    padding: 10px 20px;
    border: none;
    background: #2186F0;
    border-radius: 4px;
}

.custom_button__plus:hover {
    background: #1077E5;
    cursor: pointer;
}

.custom_button__plus:active {
    background: #303D88;
}

.custom_button__plus:focus-visible {
    background: #303D88;
    border: 2px solid #273270;
    box-sizing: border-box;
}

.custom_button__plus:disabled {
    opacity: .6;
}

.button_plus {
    margin: 0 15px 0 3px;
}

@media screen and (max-device-width: 926px) and (max-device-height: 428px), screen and (max-device-width: 428px) {

    .custom_button__plus {
        padding: 0;
        border-radius: 50%;
        width: 56px;
        height: 56px;
        position: fixed;
        right: 16px;
        bottom: 16px;
        z-index: 20;
    }

    .custom_button__plus span {
        display: none;
    }

    .button_plus {
        margin: 21px;
    }

}