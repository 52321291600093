.apps_page {
    padding-top: 15px;
}

.apps_page h2 {
    margin: 17px 0;
    text-align: start;
}

.apps_page p {
    margin: 18px 0;
    text-align: start;
}

.apps_page__applications_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
}

@media screen and (max-device-width: 1000px) {

    .apps_page p,
    .apps_page h2 {
        margin: 18px 16px;
    }


    .apps_page__applications_header {
        margin-right: 16px;
    }

}