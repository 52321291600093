.copy_icon__icon {
    margin-left: 17px;
    margin-top: 2px;
}

.copy_icon:hover {
    cursor: pointer;
}

.copy_icon__icon:hover path {
    fill: #273270;
}