.profile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
}

.profile__list_item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 400px;
    margin-top: 10px;
    border-bottom: 1px solid lightgrey;
}

.profile__list_item span {
    margin: 10px 0;
}

.profile__name, .profile__name_edit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 400px;
}

.profile__edit_icon:hover {
    cursor: pointer;
    background-color: lightgrey;
    border-radius: 50%;
    padding: 3px;
}

.hidden {
    display: none;
}

.profile__name_edit {
    margin: .8em 0;
}

.profile__name_edit input {
    font-size: 1.5em;
    font-weight: bold;
    /* padding: 0; */
}

.profile__name_edit .MuiInput-underline:after {
    border-bottom: 1px solid dodgerblue;
}

.profile__wrapper {
    padding: 15px;
}