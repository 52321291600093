.sort_icon {
    margin: 0 0 0 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sort_icon:hover {
    cursor: pointer;
}

.sort_date_cell:hover path {
    fill: #273270;
}

.rotate {
    transform: rotate(180deg);
}