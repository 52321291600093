.accordion_app__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.accordion_app__header__app_name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.accordion_app__header__app_name span {
    width: 164px;
    text-align: left;
}

.app_icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: #EFEFF3;
    border-radius: 50%;
    margin: 3px 7px;
}

.accordion_app__header__status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-right: 70px;
    width: 200px;
}

.accordion_app__header__status_icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: 12px;
}

.accordion_app__header__status_icon img {
    margin-top: 5px;
}

.accordion_app__header__status_text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.hidden_status {
    display: none;
}

.accordion_app__header__app_name span {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #273270;
    margin-left: 14px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.status_text {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #273270;
}

.status_action {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #2186F0;
}

.status_action:hover {
    text-decoration: underline;
}

.accordion_app__header__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 105px;
}

.accordion_app__content__item {
    padding-left: 85px;
}

.border_bottom {
    border-bottom: 1px solid #E0E2E7;
}

.accordion_app__settings {
    display: none;
    height: 20px;
}

body .MuiList-root {
    padding: 0;
}

@media screen and (max-device-width: 926px) and (max-device-height: 428px), screen and (max-device-width: 640px) {

    .accordion_app__header__app_name span {
        width: auto;
    }

    .accordion_app__header__buttons {
        display: none;
    }

    .accordion_app__settings {
        display: flex;
    }

}

@media screen and (max-device-width: 428px) {

    .app_icon {
        margin: 3px 7px 3px 0;
    }

    .accordion_app__content__item {
        padding-left: 16px;
    }

    .accordion_app__header__status {
        margin-right: 0;
        width: auto;
        margin-left: 60px;
    }

    .accordion_app__header {
        flex-direction: column;
        align-items: flex-start;
    }

    .accordion_app__header__app_name span {
        width: 164px;
    }

}