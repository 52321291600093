.license_table {
    margin-top: 30px;
}

.hidden_block {
    display: none;
}

.license_table__no_result {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 45px;
}

.license_table__preloader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.license_table__preloader .MuiCircularProgress-root {
    color: #2186F0;
}