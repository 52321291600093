@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
.cencel_button {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #273270;
    padding: 10px 20px;
    border: none;
    background: #EFEFF3;
    border-radius: 4px;
}

.cencel_button:hover {
    background: #E6E7EB;
    cursor: pointer;
}

.cencel_button:active {
    background: #D2D2DB;
}

.cencel_button:focus-visible {
    background: #D2D2DB;
    border: 2px solid #273270;
    box-sizing: border-box;
}

.cencel_button:disabled {
    opacity: .6;
}