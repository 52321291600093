.main_page__wrapper {
    width: 100%;
    margin-top: 131px;
    flex: auto;
}

.main_page {
    width: 1000px;
    margin: 0 auto;
}

@media screen and (max-device-width: 1000px) {

    .main_page {
        width: 100vw;
    }

}