body .MuiMenuItem-root {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
}

body .MuiListItem-button:hover {
    background-color: #F6F6FB;
}

body .MuiListItem-root.Mui-selected, body .MuiListItem-root.Mui-selected:hover {
    background-color: #EFEFF3;
}

body .MuiPaper-elevation5 {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}