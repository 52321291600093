.info_auth_otp {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.info_auth_otp__header {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #273270;
    margin: 24px 0 12px;
}

.info_auth_otp__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 0;
    width: 100%;
}

.info_auth_otp__row__left_span {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #8387A0;
    width: 299px;
}

.info_auth_otp__row__right {
    display: flex;
    flex-direction: column;
}

.info_auth_otp__row__right_span {
    color: #2186F0;
}

.info_auth_otp__row__right_span:hover {
    cursor: pointer;
}

.margin_top {
    margin-top: 25px;
}

.info_auth_otp__row__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0 16px;
    width: 100%;
}

.info_auth_otp__test {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.info_auth_otp__test__input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 9px 0 3px;
    width: 501px;
}

.info_auth_otp__test__input__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 234px;
}

.info_auth_otp__test__label span {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #273270;
    white-space: pre-line;
}

.hidden_block {
    display: none;
}

@media screen and (max-device-width: 926px) and (max-device-height: 428px), screen and (max-device-width: 960px) {

    .info_auth_otp__row {
        flex-direction: column;
        align-items: flex-start;
        padding-right: 16px;
    }

    .info_auth_otp__row__right {
        margin-top: 10px;
        width: 100%;
    }

    .info_auth_otp__row__left_span {
        display: none;
    }

    .info_auth_otp__test {
        width: 100%;
    }

    .info_auth_otp__test__input__buttons {
        margin-top: 10px;
    }

}

@media screen and (max-device-width: 540px) {

    .info_auth_otp__test__input {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

}