@import url('https://fonts.googleapis.com/css2?family=Inter&family=Open+Sans:wght@600&family=Play&display=swap');

.close_button {
    position: absolute;
    top: 21px;
    right: 23px;
    color: #797A80;
    cursor: pointer;
}

.new_script{
    position: inherit;
    display: flex;
    flex-direction: column;
    top: calc(50% - 260px);
    left: calc(50% - 280px);
    width: 550px;
    background: #FFF;
    border: 1px solid #E0E2E7;
    box-sizing: border-box;
    border-radius: 6px;
}

@media screen and (max-device-width: 428px) {

    .new_script {
        top: 0;
        left: 0;
        width: 100%;
    }

    .license_card {
        width: 100%;
    }

    .license_card__body {
        padding: 0 17px;
    }

    .license_card__footer {
        padding: 15px 17px;
    }

}