.footer {
    width: 100%;
    margin-top: 87px;
}

.footer__centralized_block {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.footer__left, .footer__right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 400px;
}

.footer span {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #8387A0;
}

.footer span:hover {
    color: #273270;
    cursor: default;
}
.footer a {
    text-decoration: none;
}

.footer a:hover {
    text-decoration: none;
}

@media screen and (max-device-width: 1000px) {

    .footer__centralized_block {
        width: 100%;
        padding: 0 16px;
    }

}

@media screen and (max-device-width: 926px) and (max-device-height: 540px), screen and (max-device-width: 540px) {

    .MuiPaper-root .footer {
        margin: 0;
        flex-shrink: 0;
    }

    .MuiPaper-root .footer__centralized_block {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin: 0;
        width: 100%;
        padding: 0;
    }

    .MuiPaper-root .footer__left {
        flex-direction: column-reverse;
        align-items: flex-start;
        width: 100%;
        padding-left: 57px;
        margin: 8px 0;
    }

    .MuiPaper-root .footer__left span, .MuiPaper-root .footer__right a {
        margin-bottom: 16px;
    }

    .MuiPaper-root .footer__right {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding-left: 57px;
        border-bottom: 1px solid  #EFEFF3;
        margin-bottom: 8px;
    }

    .MuiPaper-root .footer__right a span {
        color: #2186F0;
    }

}