.header_logo {
    position: relative;
}

.header_logo_link img {
    width: 150px;
}

.header_logo_version {
    position: absolute;
    left: 100px;
    top: 40px;
}