.info_code {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.info_code__header {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #273270;
    margin: 24px 0 12px;
}

.info_code__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 0;
    width: 100%;
}

.border_bottom {
    border-bottom: 1px solid #E0E2E7;
}

.info_code__row__left_span {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #8387A0;
    width: 299px;
}

.info_code__row__right {
    display: flex;
    flex-direction: column;
}

.info_code__row__right_span {
    color: #2186F0;
}

.info_code__row__right_span:hover {
    cursor: pointer;
}

.margin_top {
    margin-top: 25px;
}

.hidden_block {
    display: none;
}

@media screen and (max-device-width: 926px) and (max-device-height: 428px), screen and (max-device-width: 960px) {

    .info_code__row {
        flex-direction: column;
        align-items: flex-start;
        padding-right: 16px;
    }

    .info_code__row__right {
        margin-top: 10px;
        width: 100%;
    }

}