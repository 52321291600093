.accordion_get_started__header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.accordion_get_started__header span {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #2186F0;
    margin-left: 15px;
}

.question_icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #EFEFF3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3px 7px;
}

@media screen and (max-device-width: 428px) {

    .question_icon {
        margin: 3px 7px 3px 0;
    }

}