@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.custom_button {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #fff;
    padding: 10px 20px;
    border: none;
    background: #2186F0;
    border-radius: 4px;
}

.custom_button:hover {
    background: #1077E5;
    cursor: pointer;
}

.custom_button:active {
    background: #303D88;
}

.custom_button:focus-visible {
    background: #303D88;
    border: 2px solid #273270;
    box-sizing: border-box;
}

.custom_button:disabled {
    opacity: .6;
}

.activate_balance .MuiCircularProgress-circle {
    stroke: #fff;
}
