@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.activate_balance_phone {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #fff;
    padding: 10px 20px;
    border: none;
    background: #2186F0;
    border-radius: 4px;
}

.activate_balance_phone:hover {
    background: #3B4BA8;
    cursor: pointer;
}

.activate_balance_phone:active {
    background: #303D88;
}

.activate_balance_phone:focus-visible {
    background: #303D88;
    border: 2px solid #273270;
    box-sizing: border-box;
}

.activate_balance_phone:disabled {
    opacity: .6;
}

.activate_balance_phone_disabled {
    pointer-events: none;
    cursor: pointer;
    opacity: .6;
}

.activate_balance_phone .MuiCircularProgress-circle {
    stroke: #fff;
}
