.App {
  text-align: center;
  visibility: hidden;
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body h2 {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #273270;
}

body h3 {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #273270;
}

.App p {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #8387A0;
}

.App-link {
  color: #61dafb;
}

.show_animate {
  visibility: visible;
  animation: showApp 1s ease-in-out;
}

@keyframes showApp {

  0% {
    opacity: 0.3;
    /* transform: translateY(1000px); */
  }
	
	100% {
		opacity: 1;
    /* transform: translateY(0); */
	}
	
}