.log_table {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.log_table__toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0 32px;
}

.sort_date_cell {
    justify-content: flex-start;
    width: 135px;
}

.sort_date_cell:hover span {
    color: #273270;
}

.service_phone_cell {
    padding-right: 0;
}

.log_table__no_result {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5px;
}

.log_table__no_result__span, .license_table__no_result__span {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #8387A0;
}

.hidden_block {
    display: none;
}

.log_table__preloader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.log_table__preloader .MuiCircularProgress-root {
    color: #2186F0;
}

@media screen and (max-device-width: 1000px) {

    .log_table__toolbar {
        display: grid;
        column-gap:  20px;
        row-gap: 20px;
        grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
        width: 100%;
    }

}