.delete_app_modal {
    position: absolute;
    top: calc(50% - 100px);
    left: calc(50% - 210px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 32px;
    background: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 0 96px rgba(0, 0, 0, 0.16);
    width: 420px;
    box-sizing: border-box;
}

.delete_app_modal__question {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #273270;
    margin-top: 21px;
}

.delete_app_modal h2 {
    margin: 0;
}

.delete_app_modal__row__buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 37px;
    width: 100%;
}

.delete_app_modal__row__buttons_right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 214px;
}

.close_button {
    position: absolute;
    top: 20px;
    right: 20px;
}

.delete_app_modal .MuiSvgIcon-root {
    fill: #8387A0;
}

.close_button:hover {
    cursor: pointer;
}
@media screen and (max-device-width: 420px) {

    .MuiModal-root .delete_app_modal {
        width: 100%;
        left: 0;
        padding: 25px;
    }

}