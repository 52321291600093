.add_app_modal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 32px;
    background: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 0 96px rgba(0, 0, 0, 0.16);
    width: 600px;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
}

.add_app_modal h2, .add_app_modal h3 {
    margin: 0;
}

.add_app_modal__selectors {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 33px 0 0;
}

.add_app_modal__transports {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 8px 0 0;
}

.add_app_modal__sip {
    width: 100%;
    margin-top: 16px;
}

.add_app_modal__row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px 0 8px;
    width: 100%;
}

.add_app_modal__row .MuiFormControl-root {
    width: 100%;
}

.add_app_modal__row__label {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #8387A0;
    margin-bottom: 8px;
}

.add_app_modal__row__buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 37px;
    width: 100%;
}

.edit_app_modal__row__buttons_right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 234px;
}

.hidden_block {
    display: none;
}

.close_button {
    position: absolute;
    top: 20px;
    right: 20px;
}

.close_button:hover {
    cursor: pointer;
}

@media screen and (max-device-width: 640px) {

    .add_app_modal {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 16px 24px;
        overflow-y: auto;
    }

    .add_app_modal__row__helper {
        margin-top: 24px;
    }

    .add_app_modal__transports {
        flex-wrap: wrap;
    }

    .add_app_modal__transports > div {
        margin-bottom: 8px;
    }

}