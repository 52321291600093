.settings_sip__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
}

.settings_sip__content > span {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    line-height: 1.75;
    white-space: normal;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}

.settings_sip__status {
    min-width: 190px;
}

.settings_disable {
    opacity: 0.3;
}

.settings_sip__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.settings_sip__edit_icon, .settings_sip__delete_icon {
    margin: 5px;
    opacity: .6;
}

.settings_sip__edit_icon:hover, .settings_sip__delete_icon:hover {
    opacity: 1;
    cursor: pointer;
}