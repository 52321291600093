.info_auth_oauth2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.info_auth_oauth2__header {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #273270;
    margin: 24px 0 12px;
}

.info_auth_oauth2__row_text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 17px 0;
    width: 100%;
    border-bottom: 1px solid #E0E2E7;
}

.info_auth_oauth2__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 14px 0;
    width: 100%;
}

.info_auth_oauth2__left_span {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #8387A0;
    width: 299px;
}

.info_auth_oauth2__right_span {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #273270;
}

.margin_top {
    margin-top: 25px;
}

@media screen and (max-device-width: 926px) and (max-device-height: 428px), screen and (max-device-width: 960px) {

    .info_auth_oauth2__row {
        flex-direction: column;
        align-items: flex-start;
        padding-right: 16px;
    }

    .info_auth_oauth2__left_span.margin_top {
        margin-top: 0;
        margin-bottom: 10px;
    }

}

@media screen and (max-device-width: 640px) {

    .info_auth_oauth2__row_text {
        flex-direction: column;
        align-items: flex-start;
        padding-right: 16px;
    }

}
