@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&display=swap');

.nav_menu_button  {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.006em;
    color: #797A80;
    background: #FFFFFF;
    border: none;
    /* margin: 0; */
    padding: 22px 0;
    outline: none;
    height: 64px;
}

.nav_menu_button__active {
    color: #333333;
    border-bottom: 2px solid #333333;
    height: 64px;
}

.nav_menu_button:hover {
    cursor: pointer;
}

.align {
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 4px;
}

.align span {
    padding-left: 0;
    padding-right: 15px;
}

.nav_menu_button svg path {
    fill:#797A80
}

.nav_menu_button__active svg path {
    fill:#333333
}

@media screen and (max-device-width: 540px) {

    .nav_menu_button .align span {
        display: none;
    }

    .nav_menu_button__active .align span {
        display: inherit;
    }

}