.log_page {
    padding-top: 15px;
}

.log_page h2 {
    margin: 17px 0 0;
    text-align: start;
}

.hidden_block {
    display: none;
}

@media screen and (max-device-width: 1000px) {

    .log_page {
        padding: 16px;
    }

}