@import url('https://fonts.googleapis.com/css2?family=Inter&family=Open+Sans:wght@600&family=Play&display=swap');

.balance_card {
    position: inherit;
    display: flex;
    flex-direction: column;
    top: calc(50% - 160px);
    left: calc(50% - 280px);
    width: 550px;
    background: #FFF;
    box-sizing: border-box;
    border-radius: 6px;
}

.balance_card__body {
    display: flex;
    flex-direction: column;
    padding: 0 23px 0 32px;
}

.balance_card__body h2 {
    padding-top: 24px;
    padding-bottom: 15px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
}

.balance_card__row_left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.balance_card__available {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #707683;
}

.balance_card__licenses {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.15px;
    color: #273270;
}

.balance_card__form_license_key {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #2186F0;
    text-align: start;
    margin: 10px 0 30px;
}

.balance_card__form_license_key:hover {
    text-decoration-line: underline;
    cursor: pointer;
}

.balance_card .hidden_block {
    display: none;
}

.balance_card__form {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.balance_card__form a {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2186F0;
    padding-top: 12px;
    text-decoration: none;
}

.balance_card__form span {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2186F0;
    text-decoration: none;
}

.balance_card__form .balance_card__form_success {
    padding-top: 32px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
}

.balance_card__form_count_info {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
}

.balance_card__form_count_info span {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    color: #333333;
}

.balance_card__form_label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    padding-bottom: 8px;

}

.balance_card__form_label_key {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    letter-spacing: -0.006em;
    padding-top: 24px;
}

.balance_card__footer_link {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2186F0;
    background: #FFFFFF;
    text-align: left;
    padding: 0;
    margin: 0;

}

.balance_card__footer_link_activate{
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2186F0;
    background: #FFFFFF;
    text-align: left;
    padding: 0;
    margin: 0;
    padding-top: 16px;
}

.balance_card__footer:hover .balance_card__footer_link {
    text-decoration-line: underline;
}

.balance_card__footer_link:hover {
    cursor: pointer;
}

.balance_card__form_options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 36px 0 32px;
}

.balance_card__form_options .close_balance_success {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.006em;
    color: #fff;
    padding: 10px 0;
    border: none;
    background: #2186F0;
    border-radius: 8px;
    width: 87px;
    height: 40px;
    transition: outline 0.5s ease;
	outline: 2px solid transparent;
}

.close_balance_success:hover {
    background: #1077E5;
    cursor: pointer;
}

.close_balance_success:active {
    background: #2186F0;
}

.close_balance_success:focus-visible {
    background: #2186F0;
    outline: 2px solid #333333;
    box-sizing: border-box;
}

.close_balance_success:-moz-suppressed {
    background: #2186F0;
}

.close_balance_success:disabled {
    opacity: .6;
}

.balance_card__form_buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 40px 0 32px;
}

.balance_card__form_buttons_container {
    width: 267px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.active_card {
    background: #FCFCFE;
}

.close_button {
    position: absolute;
    top: 15px;
    right: 10px;
    color: #797A80;
}

@media screen and (max-device-width: 428px) {

    .balance_card {
        width: 100%;
        top: 0;
        left: 0;
    }

    .license_card {
        width: 100%;
    }

    .license_card__body {
        padding: 0 17px;
    }

    .license_card__footer {
        padding: 15px 17px;
    }

}