body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;  /* IE and Edge */
  overflow: auto;
}

@-moz-document url-prefix() {
  html,
  body {
    scrollbar-width: none;
  }
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-device-width: 926px) and (max-device-height: 428px), screen and (max-device-width: 428px) {

  body .b24-widget-button-position-bottom-right {
    right: auto;
    left: 16px;
    bottom: 16px;
  }

  .b24-widget-button-inner-container, .bx-touch .b24-widget-button-inner-container {
    opacity: .6;
  }

  body .b24-widget-button-wrapper {
    z-index: 100;
  }

  body .b24-widget-button-shadow {
    z-index: 100;
  }

  .bx-touch .b24-widget-button-position-bottom-right .b24-widget-button-popup-triangle {
    right: 190px!important;
  }

  .bx-touch .b24-widget-button-position-bottom-right .b24-widget-button-popup {
    left: 0!important;
  }

}
