.applications_card {
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    margin-top: 25px;
}

.applications_card__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 17px 25px;
    border: 1px solid #E0E2E7;
    border-bottom: none;
}

.applications_card__header span {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #BEC3E6;
}

.applications_card__header__name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 95px;
    opacity: .3;
}

.applications_card__header__name:hover {
    opacity: 1;
}

.applications_card__header__name > span {
    color:  #273270;
}

.applications_card__header__name > span:hover {
    cursor: pointer;
}

.applications_card__header__margin_span {
    margin-right: 60px;
}

@media screen and (max-device-width: 1000px) {

    .applications_card__header {
        border-left: none;
        border-right: none;
    }

}

@media screen and (max-device-width: 926px) and (max-device-height: 428px), screen and (max-device-width: 540px) {

    .applications_card {
        margin-top: 0;
        margin-bottom: 50px;
    }

    .applications_card__header {
        display: none;
    }

}