.license_page {
    padding-top: 15px;
}

.license_page h2 {
    margin: 17px 0 0;
    text-align: start;
}

.hidden_block {
    display: none;
}

.license_page__balances {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
}

.license_page__balances_link {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2186F0;
    background: #FFFFFF;
    text-align: left;
    padding: 0;
    margin: 0;
}

@media screen and (max-device-width: 1000px) {

    .license_page {
        padding: 16px;
    }

}