.custom_expand_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: #F6F6FB;
    border-radius: 50%;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.expanded {
    transform: rotate(180deg);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}