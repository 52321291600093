.copy_link_input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.copy_link_input__label {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #8387A0;
    margin-bottom: 8px;
}

.copy_link_input__form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.copy_link_input__form input:focus-visible {
    outline: none;
}

.copy_link_input__input {
    width: 545px;
    border: 1px solid #E0E2E7;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 8px 53px 8px 10px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #2C2D2E;
    text-overflow: ellipsis;
}

.copy_link_input__input:hover {
    border: 1px solid #90A0B7;
}

.copy_link_input__input:focus-visible, .copy_link_input__input:focus, .copy_link_input__input:active {
    border: 1px solid #2186F0;
}

@media screen and (max-device-width: 926px) and (max-device-height: 428px), screen and (max-device-width: 640px) {

    .copy_link_input__form {
        width: 100%;
    }

    .copy_link_input__input {
        width: 100%;
    }

    .copy_link_input {
        width: 100%;
    }

}