@import url('https://fonts.googleapis.com/css2?family=Play&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap');

.header {
    position: fixed;
    top: 0;
    width: 100%;
    border-bottom: 1px solid #EFEFF3;
    background: #fff;
    z-index: 100;
}

.header__centralized_container {
    width: auto;
    height: 64px;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header__left_side {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header__logo {
    margin-right: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header__logo > span {
    font-family: 'Play', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.01em;
    color: #333333;
    padding: 11px;
}

.header__top_bar {
    width: 100%;
    border-bottom: none;
}

.color_span {
    color: #2186F0;
}

.header__sidebar_menu__list,
.header__sidebar_menu__list_services {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 8px 0;
}

.header__sidebar_menu__list_services {
    width: 100%;
    padding: 8px 0;
}

.navbar{
    border-top: 1px solid #EFEFF3;
}

.navbar-header{
    width: auto;
    height: 64px;
    margin: 0 24px 0 31px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.navbar-header__container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 26px;
}

.navbar-header__balance {
    margin-right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}


@media screen and (max-device-width: 1000px) {

    .header__logo > span {
        margin-left: 16px;
    }

    .header__centralized_container {
        width: 100%;
    }

}

@media screen and (max-device-width: 926px) and (max-device-height: 540px), screen and (max-device-width: 540px) {

    .header__left_side nav,
    .header__services {
        display: none;
    }

    .header__logo > span {
        margin-left: 0;
    }

}

@media screen and (max-device-width: 540px) {

    .navbar-header {
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: flex-start;
    }

    .navbar-header__balance {
        margin-top: 2px;
        margin-bottom: -22px;
        width: 100%;
        z-index: 10;
    }

    .header__right_side {
        display: none;
    }

}